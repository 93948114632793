<template lang="pug">
  .row
    .actions.col
      .left(
        v-b-tooltip.hover="{ title: deleteTooltip, placement: 'top', disabled: !deleteTooltip }"
      )
        AppIconButton.delete(
          v-if="showDelete"
          :disabled="!deleteEnabled || !hasEditPermission()"
          icon="trash-alt"
          title="company_system.delete"
          @click="$emit('delete')"
        )
      .right
        AppIconButton.add-language(
          v-if="!printPdf"
          :disabled="!addLanguageEnabled"
          icon="plus-circle"
          title="company_system.add_language"
          @click="$emit('add-language')"
        )
        AppButton.print-pdf(
          v-if="printPdf"
          title="company_system.pdf_print"
          @click="$emit('print-pdf')"
        )
        AppButton.keep(
          :disabled="!hasEditPermission() || saveDisabled"
          title="company_system.save"
          @click="$emit('save')"
        )
</template>

<script>
  // mixins
  import withPermissions from "@/mixins/withPermissions"

  export default {
    components: {
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other"),
      AppButton: () => import("@/components/elements/AppButton"),
      AppPagination: () => import("@/components/elements/AppPagination")
    },

    mixins: [withPermissions],

    props: {
      deleteTooltip: String,
      showDelete: {
        type: Boolean,
        default: true
      },
      deleteEnabled: {
        type: Boolean,
        default: false
      },
      saveDisabled: {
        type: Boolean,
        default: false
      },
      addLanguageEnabled: {
        type: Boolean,
        default: false
      },
      printPdf: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .actions
    padding-top: 20px
    max-width: 100%
    display: flex
    align-items: center
    justify-content: space-between

    ::v-deep
      svg
        path
          fill: $default-white
      .title
        color: $default-white

    ::v-deep
      .app-button
        width: auto
        min-width: 110px
        padding: 0 5px

    .delete
      background-color: $default-red
      border-color: $default-red
    .add-language
      background-color: $default-turquoise-medium
      border-color: $default-turquoise-medium
    .keep
      margin-left: 10px
      background-color: $default-purple

    .print-pdf
      background-color: $default-green
</style>
